<template>
  <div class="tile_button" @click="$emit('click', $event)">
    <i v-if="icon" :class="'icon-'+icon"></i>
    <h4>{{ label }}</h4>
  </div>
</template>

<script>
export default {
  name: "TileButton",
  props: {
    label: {
      type: String,
      required: true,
    },
    icon: {
      type: String,
    }
  },
}
</script>

<style scoped lang="scss">
@import "./../assets/scss/escan_ui_variables";

.tile_button {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border-radius: 12px;
  text-align: center;
  background: #6161E4;
  min-height: 200px;
  padding: 24px 48px;

  h1, h2, h3, h4, h5, h6{ color: #ffffff; }

  i{
    @include icon(64px, #ffffff);
    margin-bottom: 18px;
  }

  &:hover{
    background: #6161E4;
    color: #ffffff;

    i{ color: #ffffff; }

    h1, h2, h3, h4, h5, h6{ color: #ffffff; }
  }

  &:focus{
    background: $tile-button-background-color-focus;
    color: $tile-button-text-color-focus;

    i{ color: $tile-button-icon-color-focus; }

    h1, h2, h3, h4, h5, h6{ color: $tile-button-text-color-focus; }
  }

  &:active{
    background: $tile-button-background-color-active;

    i{ color: $tile-button-icon-color-active; }

    h1, h2, h3, h4, h5, h6{ color: $tile-button-text-color-active; }
  }
}
</style>