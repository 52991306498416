<template>
  <div class="row">
    <div class="col-12 text-center">
      Wybrana nagroda: <b>{{ reward.name }}</b>
    </div>

    <div class="col-12 text-center mt-4">
      <el-form>
        <el-form-item>
          <el-input
              v-model="searchString"
              size="large"
              clearable
              placeholder="Wpisz numer telefonu lub adres e-mail Uczestnika"
          >
            <template #append>
              <el-button v-on:click="searchClient(true)" >
                <i class="icon icon-search" />
              </el-button>
            </template>
          </el-input>
        </el-form-item>
      </el-form>
    </div>
  </div>

  <div class="row">
    <div class="row mt-3">
      <el-table :data="foundCustomers" :empty-text="tableEmptyText" style="width: 100%">
        <el-table-column label="Imię i nazwisko">
          <template #default="scope">
            {{ scope.row.first_name }} {{ scope.row.last_name }}
          </template>
        </el-table-column>
        <el-table-column prop="phone_number" label="Telefon"></el-table-column>
        <el-table-column prop="email" label="E-mail"></el-table-column>
        <el-table-column label="Punkty">
          <template #default="scope">
            {{ scope.row.customer_points ?? 0 }}
          </template>
        </el-table-column>
        <el-table-column label="Akcja" min-width="120px">
          <template #default="scope">
            <el-button
                v-if="(scope.row.customer_points ?? 0) >= this.reward.points_needed"
                size="large"
                type="success"
                @click="selectClient(scope.row)"
            >
              Wydaj nagrodę
            </el-button>
            <el-button type="warning" disabled v-if="(scope.row.customer_points ?? 0) < this.reward.points_needed">
              Za mało punktów
            </el-button>
          </template>
        </el-table-column>
      </el-table>
    </div>
  </div>
</template>

<script>
import api from "@/api";

export default {
  name: "FindCustomersForReward",
  props: [
      'reward'
  ],
  emits: [
      'onClientSelect'
  ],
  data() {
    return {
      searchString: '',
      searching: false,
      searchTimeout: null,
      foundCustomers: []
    }
  },
  watch: {
    searchString(newValue, oldValue) {
      if (newValue !== oldValue) {
        this.searchClient();
      }
    }
  },
  computed: {
    tableEmptyText: function() {
      if (this.searching) {
        return 'Wyszukiwanie...';
      } else if (typeof this.searchString !== 'undefined' && this.searchString.length > 2) {
        return 'Nie znaleziono klienta.';
      } else {
        return 'Brak wyników.';
      }
    }
  },
  methods: {
    searchClient(instant) {
      this.searching = true;

      clearTimeout(this.searchTimeout);

      if (this.searchString.length > 2) {
        this.searchTimeout = setTimeout(() => {
          api.searchCustomersByPhrase(this.searchString).then(response => {
            this.foundCustomers = response.data;
            this.searching = false;
          })
        }, (instant ? 0 : 500));
      } else {
        this.foundCustomers = [];
        this.searching = false;
      }
    },
    selectClient(clientData) {
      this.$emit('onClientSelect', clientData);
    }
  }
}
</script>

<style scoped>

</style>
